@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bebas-300 {
  font-family: "Bebas Neue", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.bebas-400 {
  font-family: "Bebas Neue", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.bebas-700 {
  font-family: "Bebas Neue", sans-serif;;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}



.mulish-400 {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.mulish-500 {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.mulish-600 {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.mulish-700 {
  font-family: "Mulish", sans-serif;;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.custom-scroll::-webkit-scrollbar {
	background-color: #fff;
	width: 3px;
}

.custom-scroll-::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #292a2c;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  background-color: #f9fafb;
  overflow-x: hidden;
}
.background-wave {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #f0f0f0 25%, transparent 25%),
              linear-gradient(-45deg, #f0f0f0 25%, transparent 25%),
              linear-gradient(45deg, transparent 75%, #f0f0f0 75%),
              linear-gradient(-45deg, transparent 75%, #f0f0f0 75%);
  background-size: 40px 40px;
  background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
  z-index: -1;
}
.content {
  padding: 40px;
  overflow: hidden;

}
.not_border {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.wrap-links {
  position: relative; 
}    
.back-link {
  position: absolute;
  bottom: -50px; 
  display: flex;
  align-items: center;
  font-size: 16px;
}    
.logo-placeholder {
  width: 200px;
  height: 80px;
  background-color: #EEBC4E;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}
h1 {
  color: #EEBC4E;
}

.custom-scroll-sm-white {
  scrollbar-width: thin;
  scrollbar-color: #838d9b #fff;
}

.custom-scroll-sm-white::-webkit-scrollbar {
	background-color: #fff;
	width: 7px !important;
  height: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.custom-scroll-sm-white::-webkit-scrollbar-thumb {
    border-radius: 12px;
    height: 7px;
    background-color: #838d9b;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.custom-scroll-sm-dark {
  scrollbar-width: thin;
  scrollbar-color: #e5e7eb #677281;
}

.custom-scroll-sm-dark::-webkit-scrollbar {
	background-color: #677281;
	width: 7px !important;
  height: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.custom-scroll-sm-dark::-webkit-scrollbar-thumb {
    border-radius: 12px;
    height: 7px;
    background-color: #e5e7eb;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  scrollbar-width: none;
}

.title-main {
  font-size: 51px; 
  font-weight: 700; 
  color: #EEBC4E;
}
.button {
  display: inline-block;
  padding: 10px 20px;
  width: 180px;
  background-color: #5B5C66;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  margin-right: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  background-color: #626060;
}
.widget-grid {
  position: relative;
  transform-style: preserve-3d;
  width: 100%;
  height: 900px;
  animation: floatGrid 10s ease-in-out infinite;
}
@keyframes floatGrid {
  0%, 100% { transform: perspective(1000px) rotateX(15deg) rotateY(-20deg) translateZ(0); }
  50% { transform: perspective(1000px) rotateX(15deg) rotateY(-15deg) translateZ(25px); }
}
.widget {
  position: absolute;
  width: 30%;
  height: 30%;
  /* background-color: white; */
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}
.widget:hover {
  transform: translateZ(50px) scale(1.1);
  box-shadow: 0 0 30px rgba(0,0,0,0.3);
  z-index: 10;
  cursor: pointer; 
}
.widget img {
  width: 100%;
  height: 100%;
  background-color: white;
  object-fit: contain;
}
.widget-description {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
.widget:hover .widget-description {
  opacity: 1;
}
.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgba(128, 0, 128, 0.1) 1px, transparent 1px),
                  linear-gradient(to bottom, rgba(128, 0, 128, 0.1) 1px, transparent 1px);
  background-size: 33.33% 33.33%;
  transform: translateZ(1px);
}
@media (max-width: 767px) {
   .widget {
      height: 29%;
      padding: 5px;
  } 
  .grid-overlay {
      background-size: 50% 33.33%;
  }
  .button {
    padding: 5px 10px;
    width: 100%;
    margin-top: 0px;
    margin-right: 10px;
  }
  .wrap-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }    
  .widget-grid {
    height: 450px;
  }
}

@keyframes ticker-scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.ticker-track {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.ticker-content {
  display: flex;
  animation: ticker-scroll 50s linear infinite;
}

/* Pause animation on hover */
.ticker-track:hover .ticker-content {
  animation-play-state: paused;
}
